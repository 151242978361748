import { inject, Injectable } from '@angular/core';
import { RmaSrcsetItem } from '@rma/generic/domain/types';
import { Environment } from '@rma/generic/util/environment';
import { FeatureFlags, FeatureProviderService } from '@rma/generic/util/feature-flags';
import { ImageCacheType, isRmaImageCacheSize, RmaImageCacheSize, RmaImageSize } from './domain/image-optimise.constants';

@Injectable({
  providedIn: 'root',
})
export class ImageOptimiseService {
  private readonly featureProviderService = inject(FeatureProviderService);
  private readonly environment = inject(Environment);

  public getFromCdn(value: string | undefined | null, params: RmaImageSize = 'q_auto:eco,f_auto', cache: ImageCacheType = 'cl'): string {
    if (!value) {
      return '';
    }

    const { cdnUrl, cloudinaryBase, cloudinarySuffix } = this.environment.assets;
    const isCdn = value.includes(cdnUrl);

    if (!isCdn) {
      return value;
    }

    if (this.featureProviderService.getFeature(FeatureFlags.EnableImageCaching, false)) {
      if (isRmaImageCacheSize(params)) {
        return value.replace(cdnUrl, `${cloudinaryBase}${RmaImageCacheSize[params]}/${cache}${cloudinarySuffix}`);
      }
    }

    if (this.featureProviderService.getFeature(FeatureFlags.DisableCloudinary, false)) {
      return value;
    }

    return value.replace(cdnUrl, `${cloudinaryBase}${params}${cloudinarySuffix}`);
  }

  public getSrcSetFromCdn(value: string, config: RmaSrcsetItem[]): string {
    return config.map((i) => `${this.getFromCdn(value, i.optimisation)} ${i.width}`).join();
  }
}
