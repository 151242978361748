import { inject, Pipe, PipeTransform } from '@angular/core';
import { ImageCacheType, RmaImageSize } from './domain/image-optimise.constants';
import { ImageOptimiseService } from './image-optimise.service';

@Pipe({
  name: 'imageOptimise',
  standalone: true,
})
export class ImageOptimisePipe implements PipeTransform {
  private readonly imageOptimiseService = inject(ImageOptimiseService);

  transform(value: string | undefined | null, params: RmaImageSize = 'q_auto:eco,f_auto', cache: ImageCacheType = 'cl'): string {
    return this.imageOptimiseService.getFromCdn(value, params, cache);
  }
}
